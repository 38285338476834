"use client"
import { createSupabaseServerClient } from "@/services/supabase/supabase-server"
import { createClient } from "@supabase/supabase-js"
import { MyDatabase } from "@/utils/types/database"
import { createBrowserClient } from "@supabase/ssr"

export const createSupabaseClient = () => {
  if (typeof window !== "undefined") {
    return createBrowserClient<MyDatabase>(
      process.env.NEXT_PUBLIC_SUPABASE_URL!,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
      {
        auth: {
          debug: false,
        },
      },
    )
  }
  return createClient<MyDatabase>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  )
}

export const supabaseClient = createSupabaseClient()
