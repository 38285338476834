"use client"
import { getCookie } from "cookies-next"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

if (typeof window !== "undefined") {
  console.info("Initializing PostHog")
  console.info("location.hostname", location.hostname)

  const posthogBootstrapDataRaw = getCookie("posthogBootstrapData")

  let bootstrap = {}

  if (posthogBootstrapDataRaw) {
    const posthogBootstrapData = JSON.parse(posthogBootstrapDataRaw)
    bootstrap = posthogBootstrapData
  }

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_INGEST_HOST,
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    bootstrap: bootstrap,
    persistence:
      getCookie("analyticalCookies") === "true"
        ? "localStorage+cookie"
        : "memory",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug()
    },
  })
}
export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
