"use client"
import React from "react"
import { SessionContextProvider } from "@supabase/auth-helpers-react"
import { supabaseClient } from "@/services/supabase/supabase-client"

interface SupabaseSessionContextProviderProps {
  children: React.ReactNode
}
export const SupabaseSessionContextProvider = (
  props: SupabaseSessionContextProviderProps,
) => {
  return (
    <SessionContextProvider supabaseClient={supabaseClient}>
      {props.children}
    </SessionContextProvider>
  )
}
