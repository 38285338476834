"use client"
import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardContent,
  CardFooter,
  Card,
} from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { TypographyP } from "@/components/ui/typography"
import { useState } from "react"
import { getCookie, setCookie } from "cookies-next"
import posthog from "posthog-js"

export function CookieBanner() {
  const cookieBannerCookie = getCookie("show-cookie-banner") === "true"

  const [showCookieBanner, setShowCookieBanner] = useState(cookieBannerCookie)
  const [functionalCookies, setFunctionalCookies] = useState(true)
  const [analyticsCookies, setAnalyticsCookies] = useState(true)
  const [marketingCookies, setMarketingCookies] = useState(true)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    setCookie("functionalCookies", functionalCookies ? "true" : "false", {
      maxAge: 60 * 60 * 24 * 365,
    })

    setCookie("analyticalCookies", analyticsCookies ? "true" : "false", {
      maxAge: 60 * 60 * 24 * 365,
    })

    setCookie("marketingCookies", marketingCookies ? "true" : "false", {
      maxAge: 60 * 60 * 24 * 365,
    })

    posthog.set_config({
      persistence: analyticsCookies ? "localStorage+cookie" : "memory",
    })

    setCookie("show-cookie-banner", "false", { maxAge: 60 * 60 * 24 * 365 })
    setShowCookieBanner(false)
  }

  if (!showCookieBanner) return null

  return (
    <div className="fixed bottom-8 md:bottom-0 z-40 px-4 py-6 animate-fadeInUp duration-200">
      <Card key="1" className="w-full max-w-lg">
        <CardHeader className="pb-4">
          <div className="flex items-center">
            <CookieIcon className="mr-2" />
            <CardTitle>Cookie Preferences</CardTitle>
          </div>
          <CardDescription>
            Manage your cookie settings. You can enable or disable different
            types of cookies below.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-3">
          <div className="flex justify-between items-center space-y-2">
            <div>
              <Label htmlFor="essential">Essential Cookies</Label>
              <TypographyP className="text-sm font-light mr-1">
                These cookies are necessary for the website to function and
                cannot be switched off.
              </TypographyP>
            </div>
            <Switch
              className="ml-auto"
              id="essential"
              checked={functionalCookies}
              onCheckedChange={setFunctionalCookies}
              disabled
            />
          </div>
          <div className="flex justify-between items-center space-y-2">
            <div>
              <Label htmlFor="analytics">Analytics Cookies</Label>
              <TypographyP className="text-sm font-light mr-1">
                These cookies allow us to count visits and traffic sources, so
                we can measure and improve the performance of our site.
              </TypographyP>
            </div>
            <Switch
              className="ml-auto"
              id="analytics"
              checked={analyticsCookies}
              onCheckedChange={setAnalyticsCookies}
            />
          </div>
          {/* <div className="flex justify-between items-center space-y-2">
            <div>
              <Label htmlFor="marketing">Marketing Cookies</Label>
              <TypographyP className="text-sm font-light mr-1">
                These cookies help us show you relevant ads.
              </TypographyP>
            </div>
            <Switch
              className="ml-auto"
              id="marketing"
              checked={marketingCookies}
              onCheckedChange={setMarketingCookies}
            />
          </div> */}
        </CardContent>
        <Separator />
        <CardFooter className="py-4">
          <Button className="ml-auto" type="submit" onClick={handleSubmit}>
            Save Preferences
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}

function CookieIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 2a10 10 0 1 0 10 10 4 4 0 0 1-5-5 4 4 0 0 1-5-5" />
      <path d="M8.5 8.5v.01" />
      <path d="M16 15.5v.01" />
      <path d="M12 12v.01" />
      <path d="M11 17v.01" />
      <path d="M7 14v.01" />
    </svg>
  )
}
